
import DatePicker from "@/components/utils/IDatePicker.vue";
import ValidationMixin from "@/mixins/validation";
import {
  TicketCategory,
  CategoryTypes,
} from "@/store/modules/category/category.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const categoryX = namespace("Category");

@Component({
  components: { DatePicker },
})
export default class EditCategory extends Mixins(ValidationMixin) {
  @categoryX.State(CategoryTypes.UPDATE_CATEGORY_STATE)
  public updateCategory!: DefaultState;

  @categoryX.State(CategoryTypes.CATEGORY_DATA)
  public ticketCategory!: TicketCategory;

  @categoryX.Mutation(CategoryTypes.SET_UPDATE_CATEGORY_DIALOG)
  public setUpdateCategory!: (updateCategory: boolean) => void;

  @categoryX.Action(CategoryTypes.UPDATE_CATEGORY)
  public saveCategory!: (farmer: TicketCategory) => Promise<void>;

  public updateCategoryStep = 1;

  public isValid = false;

  public category: TicketCategory = {
    id: -1,
    name: "",
    description: "",
    active: false,
    createdAt: new Date(),
  };

  @Ref("form") public formRef!: Validator;

  closeUpdateCategoryDialog(): void {
    this.setUpdateCategory(false);
  }

  async saveCategoryToDb(): Promise<void> {
    await this.formRef.validate();

    if (this.isValid) {
      await this.saveCategory(this.category);
    }
  }

  @Watch("ticketCategory")
  onDataChanged(value?: TicketCategory): void {
    this.category = Object.assign({}, value);
  }

  mounted(): void {
    this.category = Object.assign({}, this.ticketCategory);
  }
}
